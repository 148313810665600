import React from "react";
import Accordion from '../context/Accordion';
import '../Css/Accordion.css'

const FractionalCFOTest = ({ data }) => {
    const { banner, title, description, first_section, second_section } = data;

    const accordionItems = second_section ? second_section.answers.map((answer, index) => ({
        title: answer.question,
        content: answer.answer
    })) : [];

    return (
        <div>
            <img src={banner} width='100%' alt="CFO Banner"/>
            <h2 className="bookkeeping_title">{title}</h2>
            <p className="bookkeeping_desc">{description}</p>
            <div className="bookkeeping_container">
                <h2 className="bookkeeping_question">{first_section.title}</h2>
                <div className="bookkeeping_section fractional-cfo-section">
                    {first_section.answers.map((answer, index) => (
                        <div key={index} className="bookkeeping_point">
                            <h5>{answer.title}</h5>
                            {answer.description}
                        </div>
                    ))}
                </div>
            </div>
            {second_section && (
                <div>
                    <h1 className="accordion-title">{second_section.title}</h1>
                    <Accordion items={accordionItems} />
                </div>
            )}
        </div>
    );
}

export default FractionalCFOTest;
