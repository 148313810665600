import React, { useState } from 'react';
import '../Css/Accordion.css'

function Accordion({ items }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="accordion">
    {items.map((item, index) => (
        <div className="accordion-item" key={index}>
        <div
            className={`accordion-header ${index === openIndex ? 'active' : ''}`}
            onClick={() => toggleAccordion(index)}
        >
            {index + 1}. {item.title}
        </div>
        {index === openIndex && (
            <div className="accordion-content">{item.content}</div>
        )}
        </div>
    ))}
    </div>

  );
}

export default Accordion;
