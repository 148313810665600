import React, { useCallback, useContext, useEffect, useState } from "react";
import PricingOptions from "../Constant/Pricing";
import '../Css/Pricing.css';
import { useCart } from "../context/CartProvider";
import { FaCheck, FaCheckCircle  } from "react-icons/fa";
import SliderRuleComponent from "../Components/SliderRule";
import PlanComponent from "../Components/PlanComponent";
import { PlansContext } from "../context/PlansProvider";
import { render } from "@testing-library/react";


function Pricing() {
  const [activeTab, setActiveTab] = useState("Bookkeeping");
  const {allPlans} = useContext(PlansContext);
  const { cartPlans, isCartOpen, addToCart, toggleCartOpen } = useCart();
  const [addedPlans, setAddedPlans] = useState({});

  const renderPlans = useCallback((allPlans, type) => {
    const plansByType = allPlans.filter(plan => plan.type === type);
    const categoryMap = {};
    for(let plan of plansByType) {
      if(plan.category in categoryMap){
        categoryMap[plan.category].push(plan);
      } else {
        categoryMap[plan.category] = [plan];
      }
    }

    return (
      <>
        {Object.keys(categoryMap).map((category, categoryIndex) => {
          return (
            <>
            <div key={"plan_category_"+categoryIndex}>
                {category && <h3>{category}</h3>}
                <div className="plan-container">
                  <div className="plan-header">
                    <div>
                      <div>Plan Name</div>
                      <div className="pricing-title-desc">Description</div>
                      <div>Price</div>
                    </div>
                  </div>
                  <div className="plan-grid">
                    {categoryMap[category].map((element, index) => {
                      return (<PlanComponent key = {`plan_${index}`} plan = {element} index={index} isCartPlan={false}></PlanComponent>)
                    })}
                  </div>
                </div>
            </div>
            </>
          )
        })}
      </>
    )
  }, [allPlans, activeTab])

  const onAddClick = useCallback((plan) => {
    addToCart(plan);
  }, [addToCart]);

  const handleAddPlan = (index, categoryIndex) => {
    const planKey = `${index}_${categoryIndex}`;
    if (!addedPlans[planKey]) {
      onAddClick(index, categoryIndex);
      setAddedPlans(prevState => ({
        ...prevState,
        [planKey]: true
      }));
    } else {
      setAddedPlans(prevState => ({
        ...prevState,
        [planKey]: false
      }));
    }
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


  // const renderPlanGrid = useCallback((plans, categoryIndex)=>{
  //   console.log("render plans: ", plans);
  //   return (
  //     <div className="plan-container">
  //       <div className="plan-header">
  //         <div>
  //           <div>Plan Name</div>
  //           <div className="pricing-title-desc">Description</div>
  //           <div>Price</div>
  //         </div>
  //       </div>
  //       <div className="plan-grid">
  //         {
  //           plans.map((plan,index)=>(
  //             // <div key={"plan_"+index} className="plan">
  //             //   <div className="pricing-content-section">
  //             //     <h4>{plan.name}</h4>
  //             //     <div className="content">
  //             //       <p className="description">{plan.description}</p>
  //             //       {plan.sliderRequired && 
  //             //         <SliderRuleComponent sliderName={"plan_"+index+"_slider"} setSliderValueFromParent={setSliderValueFromParent}></SliderRuleComponent>
  //             //       }
  //             //       {
  //             //         plan.features && plan.features.length !== 0 && <ul className="features">
  //             //           {plan.features.map((feature,i)=>(
  //             //             <li key={"plan_"+index+"_feature_"+i}>{feature}</li>
  //             //           ))}
  //             //         </ul>
  //             //       }
  //             //     </div>
  //             //   </div>
  //             //   <div className="action">
  //             //     {
  //             //       plan.prices && plan.prices.map((price,pi)=>(
  //             //         <div key={"price_"+index+"_"+pi} className={`price ${plan.prices.length >= 2 ? 'inline' : ''}`}>
  //             //           <span className="amount">{price.price}</span>
  //             //           <span className="duration">{price.duration}</span>
  //             //         </div>
  //             //       ))
  //             //     }
  //             //     <button
  //             //       onClick={() => handleAddPlan(index, categoryIndex)}
  //             //       className={`add_btn ${addedPlans[`${index}_${categoryIndex}`] ? "added" : ""}`}
  //             //     >
  //             //       {addedPlans[`${index}_${categoryIndex}`] ? (
  //             //         <>
  //             //           <span><FaCheckCircle className="checked_icon"/></span>
  //             //           <span className="text">Plan Added</span>
  //             //         </>
  //             //       ) : (
  //             //         "Add Plan"
  //             //       )}
  //             //     </button>
  //             //   </div>
  //             // </div>
  //             <PlanComponent plan = {plan} index={index} isCartPlan={false} categoryIndex={categoryIndex}></PlanComponent>
  //           ))
  //         }
  //       </div>
  //     </div>
  //   );
  // },[activeTab, addedPlans]);

  return (
    <div>
      <h1 className="pricing_title">Pricing</h1>
      <div className="pricing-container">
        <div className="tab-container">
          {Object.keys(PricingOptions).map((tabName, index) => (
            <div
              key={index}
              className={`tab ${activeTab === tabName ? "active" : ""}`}
              onClick={() => handleTabClick(tabName)}
            >
              {tabName}
            </div>
          ))}
        </div>
        <div className="tab-content">
          {/* {
            PricingOptions[activeTab]?.plans ? renderPlanGrid(PricingOptions[activeTab].plans) : <></>
          }
          {
            PricingOptions[activeTab]?.categories?.map((category,index)=>(
              <div key={"plan_category_"+index}>
                <h3>{category.name}</h3>
                {category?.plans && renderPlanGrid(category.plans, index)}
              </div>
            ))
          } */}
          {renderPlans(allPlans, activeTab)}
        </div>
      </div>
      {!isCartOpen && cartPlans.length > 0 && <div className="floating_cart" >
      <div className="floating_cart_text" > {cartPlans.length}&nbsp;{cartPlans.length === 1? "Plan": "Plans"} Added</div>
      <div className="floating_cart_view_link" onClick={() => toggleCartOpen()}>VIEW</div>
      
    </div>}
    </div>
  );
}

export default Pricing;
