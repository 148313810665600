import { useContext, createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';import userApis from '../Apis/UserApi';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
    // signInWithRedirect(auth, provider)
  };

  const logOut = () => {
      signOut(auth);
      navigate('/');
  }

  const setUserInformationFromDbByAuthServiceId = async(authServiceId, email) => {
      let user = await userApis.getUserByEmail(email);
      if(user === null) {
        user = await userApis.registerUserByFirebase(email, authServiceId);
        // setUser(user);
        // console.log("User is: ", user);
        const timer = setInterval(() => {
          if(user) {
            navigate('/profile');
            clearInterval(timer);
          } 
        }, 1000);
      }
      setUser(user);
  }

  useEffect(() => {
    //This useEffect will only be called once when component mounts as dependency array is empty. But onAuthStateChanged is a listener attached to internal auth 
    //state maintained by firebase on client side. Whenever user state changes it will run the callback function defined which will set the user.
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      //If firebase user exists, code to register new user or get user from db and set user as that user
      if(currentUser){
        const authServiceId = currentUser.uid;
        const email = currentUser.email;
        setUserInformationFromDbByAuthServiceId(authServiceId, email);
      }
      else {
        setUser(currentUser);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};