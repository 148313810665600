import React from "react";
import Accordion from '../context/Accordion';
import '../Css/BookKeeping.css'
import '../Css/Accordion.css'
import bookkeeping_image from '../Assets/bookkeeping.png'


const BookKeeping = () => {
   
    const accordionItems = [
        { title: 'Meet your dedicated bookkeeping expert', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
        { title: 'Mend your transactions - automatically', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
        { title: 'Get your financial report, get back to business', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
        { title: 'Add tax or CFO services, if you need it', content: "Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows." },
    ];

   return (
    <div>
        <img src={bookkeeping_image} width='100%'/>
        <h2 className="bookkeeping_title">The best bookkeeping for growing businesses</h2>
        <p className="bookkeeping_desc">You didn't start a business to track your cash flow or reconcile accounts. But we did. Sage gives you the freedom to focus on your business, knowing you'll receive accurate, detailed books every month.</p>
        <div className="bookkeeping_container">
                <h2 className="bookkeeping_question">Why companies like yours choose Sage</h2>
                <div className="bookkeeping_section">
                <div className="bookkeeping_point">
                    <h5>Unmatched Accuracy</h5>
                Get accurate books delivered monthly, on the dot. Our expert team is assisted by powerful bookkeeping software that automatically eliminates common errors.
                </div>
                <div className="bookkeeping_point">
                    <h5>Expert support and partnership</h5>
                Get a finance expert dedicated to your business bookkeeping services. Beyond managing your books and taxes, we help you select the right payroll provider, offer financial best practices, and more.
                </div>
                <div className="bookkeeping_point">
                    <h5>Built for growing businesses</h5>
                With accrual basis bookkeeping and burn rate reports, we are built for growing businesses. We integrate with hundreds of tools like Bill.com, Stripe, and Gusto.
                </div>
                <div className="bookkeeping_point">
                    <h5>Unmatched Accuracy</h5>
                Beyond bookkeeping, we're here for your growing financial needs. With Tax or CFO Services, we support everything from filing business income taxes to building investor decks.  
                </div>
                </div>
                
        </div>
        <div>

        </div>
        <h1 className="accordion-title">Getting started with Sage is easy</h1>
        <Accordion items={accordionItems} />

    </div>
   )
}

export default BookKeeping