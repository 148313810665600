import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Cart from "../Cart";
import { IoCloudyNight } from "react-icons/io5";

function Layout(){
    return (
        <div className="App">
            
            <Header />
            <Cart />
            <Outlet />
            
            <Footer />
        </div>
    );
}
export default Layout;
