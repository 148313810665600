import { useState } from "react";
import '../Css/SliderRule.css';
import { debounce } from "lodash";

function SliderRuleComponent({sliderName, value, setValue, min_val, max_val}) {

    return (<>
        <div className="slider-container">
            <input type="range" name = {sliderName} className="slider-rule" id = "rangeInput" value = {value} min={min_val} max={max_val} 
            onChange={(event) => {
                setValue(event.target.value);
            }}
            />
        </div>
    </>);
}

export default SliderRuleComponent;