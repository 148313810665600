const footer = {
    support:{
        "phone": "(650) 396-34550",
        "email":"hello@trysage.finance"
    },
    menu:{
        Company:{
            "About":"",
            // "Blog":"",
            "Contact Us": "",
            "Pricing": "/pricing",
            // "Testimonials": ""
        },
        // Support:{
        //     "Help Center":"",
        //     "Terms of Service":"",
        //     "Legal": "",
        //     "Privacy Policy": "",
        //     "Status": ""
        // }

    }
    
 }
 
 export default footer
 
 