import React, {useEffect, useState} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';
import Modal from 'react-modal';
import loginpopup from '../Assets/login_popup.jpg';
import logo from '../Assets/logo.png';

export const LoginPopup = ({ isOpen, setIsModalOpen, handleSignOut }) => {
    const [user] = useAuthState(auth);
    const [email, setEmail] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);
    const [loginError, setLoginError] = useState('');

    const [infoMsg, setInfoMsg] = useState('');

    const [initialLoading, setInitialLoading] = useState(false);
    const [initialError, setInitialError] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const {search} = location;

    useEffect(()=>{
        if(user){
        }
        else{
          if(isSignInWithEmailLink(auth, window.location.href)){
            let email = localStorage.getItem('email');
            if(!email){
              email = window.prompt('Please provide your email');
            }
            setInitialLoading(true);
            signInWithEmailLink(auth, localStorage.getItem('email'), window.location.href)
            .then((result)=>{
              localStorage.removeItem('email');
              setInitialLoading(false);
              setInitialError('');
              navigate('/');
            }).catch((err)=>{
              setInitialLoading(false);
              setInitialError(err.message);
              navigate('/');
            })
          }
          else{

          }
        }
      },[user, search, navigate]);

    const handleLogin=(e)=>{
        e.preventDefault();
        setLoginLoading(true);
        sendSignInLinkToEmail(auth, email, {
          url: 'http://localhost:3000/',
          handleCodeInApp: true,
        }).then(()=>{
          localStorage.setItem('email', email);
          setLoginLoading(false);
          setLoginError('');
          setInfoMsg('We have sent you an email with a link to sign in');
        }).catch(err=>{
          setLoginLoading(false);
          setLoginError(err.message);
        })
      }


    return (
        <Modal
            className="login-popup"
            isOpen={isOpen}
            onRequestClose={() => setIsModalOpen(false)}
        >
            <div className="login-popup-active">
                <div className="login-popup-left">
                    <img src={loginpopup} className="login-popup-left-bg"/>
                    <div className="login-popup-left-section">
                        <h3 className="login-popup-left-text">Your Trusted Companion for Tax Form Assistance </h3>
                        <img src={logo} className="login-popup-left-logo"/>
                    </div>
                </div>
                <div className="login-popup-right-section">
                    <h3 className="login-popup-right-text1">Welcome to Sage</h3>
                    <h4 className="login-popup-right-text2">Log in to your account</h4>
                    <div className='box'>
      {initialLoading?(
        <div>Loading...</div>
      ):(
        <>
          {initialError!==''?(
            <div className='error-msg'>{initialError}</div>
          ):(
            <>
              {user ?(

                <div>{user.first_name ? user.first_name : 'Guest'} ,<button className="header_logout" onClick={handleSignOut}> Logout</button></div>
              ):(
                <form className='form-group custom-form' onSubmit={handleLogin}>
                  <input type={'email'} required placeholder='Enter your email address'
                  className='form-control login-popup-right-input'
                  value={email||''} onChange={(e)=>setEmail(e.target.value)}/>
                  <button type='submit' className='btn btn-success btn-md login-popup-right-button'>
                    {loginLoading?(
                      <span>Logging you in</span>
                    ):(
                      <span>Login with Email</span>
                    )}
                  </button>
                  {loginError!==''&&(
                    <div className='error-msg'>{loginError}</div>
                  )}
                  {infoMsg!==''&&(
                    <div className='info-msg'>{infoMsg}</div>
                  )}
                </form>
              )}
            </>
          )}
        </>
      )}
    </div>
                    {/* <h5 className="login-popup-right-text3">Enter your email to get started</h5>
                    <input placeholder="Enter Your Email address" className="login-popup-right-input"/> */}
                    {/* <button className="login-popup-right-button">Login with Email</button> */}
                    <p className="login-popup-right-text4">Get instant access with a secure code emailed to you.</p>
                    <p className="login-popup-right-text5">Not registered? Create an account</p>
                </div>
            </div>
        </Modal>
    );
};
