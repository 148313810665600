// Cart.js
import React, { useCallback } from "react";
import { useCart } from "../context/CartProvider";
import cart_icon from "../Assets/cart.png";
import "../Css/Cart.css";
import "../Css/SlideCart.css";
import Drawer from 'react-modern-drawer';
import PlanComponent from "../Components/PlanComponent";
import cancelIcon from "../Assets/multiply.png";

import noPlanPic from "../Assets/no plan.png"
import useModal from "../hooks/useModal";

const Cart = () => {
  const { cartPlans, isCartOpen, removeCartPlan, updateCartPlan, toggleCartOpen } = useCart();
  const {setModalVisibility, setIsCartSubmit} = useModal();
  const renderCartPlans = (cartPlans) => {
    return (
      <>
        {cartPlans.map((cartPlan, index) => {
          return (
            <>
              <div className="cart-plan-container-div">
                <PlanComponent key = {`cart_plan_${index}`} isCartPlan={true} plan={cartPlan} index={index}></PlanComponent>
              </div>
            </>
          )
        })}
      </>
    )

  }
  const calculateSubtotal = useCallback((cartPlans) => {
    let res = "";
    let subtotal = 0;
    let isCustomPlanIncluded = false;
    for(let cartPlan of cartPlans) {
      if(!cartPlan.plan_price.includes("Custom")) {
        subtotal += parseFloat(cartPlan.plan_price);
      } else {
        isCustomPlanIncluded = true;
        subtotal += parseFloat(cartPlan.plan_price.match(/\d+/g).join(''))
      }
    }
    res += subtotal.toFixed(2);
    if(isCustomPlanIncluded) {
      res += "+ custom billing"
    }
    return res;
  }, [cartPlans])
  return (
    <>
    <Drawer
      open = {isCartOpen}
      onClose={() => {}}
      size = {window.innerWidth > 480? "400px": "100vw"}
      direction="right"
      enableOverlay= {false}
    >
      <div className="cart-container">
        <div className="cart-header">
          <div className="cart-header-text">Added Plans</div>
          <img src={cancelIcon} alt="close-cart" className="close-cart-icon" onClick={() => {toggleCartOpen()}}/>
          
        </div>
        <div className="cart-body">
          {cartPlans.length > 0 && renderCartPlans(cartPlans)}
          {cartPlans.length === 0 &&
            <div className="noplan-container">
              <div className="noplan-card-img">
                <img src={noPlanPic} alt="" />
              </div>
              <div className="noplan-title">
                No Plans Added
              </div>
              <div className="noplan-content">
              You can go to Pricing screen to add more plans.
              </div>
            </div>
          }
          {cartPlans.length > 0 && 
          <>
          <div className="separator-container">
            <hr className="list-separator"/>
          </div>
            <div className="cart-price-info">
              <div className="cart-price-header">
                Bill Details:
              </div>
              <div className="bill-line-container">
                <div className="bill-line-key">
                  Subtotal: 
                </div>
                <div className="bill-line-value">
                  {`$`}{calculateSubtotal(cartPlans)}
                </div>
              </div>
              <div className="bill-line-container">
                <div className="bill-line-key">
                    Discount: 
                  </div>
                  <div className="bill-line-value discount">
                    {`$`}{0}
                  </div>
              </div>
              <div className="bill-line-container">
              <div className="bill-line-key">
                  Total: 
                </div>
                <div className="bill-line-value">
                  {`$`}{calculateSubtotal(cartPlans)}
                </div>
              </div>
            </div>

            <div className="cart-submit-button" onClick = {() => {
                setIsCartSubmit(true);
                setModalVisibility(true);
            }}>
              Talk to an expert
            </div>
            </>
          }
        </div>
      </div>
    </Drawer>
    </>
  );
};

export default Cart;
