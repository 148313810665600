import React from "react";
import '../Css/OtherServices.css';
import adhoc_girl from '../Assets/ad-hoc_girl.png'
import otherservices from '../Constant/OtherServices'
import adhoc_image from '../Assets/adhoc-accounting.png'

const OtherServices = () => {
    return(
        <div>
            <img src={adhoc_image} width='100%'/>
            <h2 className="other_service_title">Ad- Hoc Accounting</h2>
            <p className="other_service_desc">Your business could save up to $250,000 through the R&D tax credit. Sage helps you maximize your savings while doing the heavy lifting. We handle the whole process so you can skip the paperwork and get back to business.</p>
            <div className="container">
                <h2 className="other_service_question">What is Ad- Hoc Accounting</h2>
                <p className="other_service_answer">Does your business incur research and development costs in the US? Then you may be able to claim a sizable tax credit of up to $250,000 or more. Once approved by the IRS, the R&D credit is applied towards your business's future payroll tax liabilities. Businesses across many industries can qualify for the R&D credit - including technology, ecommerce, bio-tech, hardware, and more.</p>
            </div>
                <h2 className="other_service_title2">Claim the R&D tax credit, without the headache</h2>
                <div className="other_service_desc2">
                    <p>You could save thousands through the R&D credit, so it's important it's done right. We handle the tax claims process from start to finish and provide the necessary documentation to back up your claim with the IRS. Claiming the R&D tax credit can be complicated and tedious, but we make it easy.</p>
                    <img src={adhoc_girl} />
                </div>
            <div className='other_services_section'>
            {Object.values(otherservices).map((otherservice, index) => (
                <div key={index} className="other_services_section_options">
                    <div className="other_services_section_top"><img src={otherservice.icon } className="other_services_section_icon"/><span className='other_services_section_title'>{otherservice.title}</span></div>
                    <div className='other_services_section_desc'>{otherservice.description}</div>
                </div>
            ))}
            </div>

        </div>
    )
}


export default OtherServices