const PricingOptions = {
    "Bookkeeping" : {
        plans: [
            {
                name: "PAYG Bookkeeping",
                description:"Pay As You Go (PAYG) monthly books cleanup ",
                prices:[
                    {
                        price: "$1",
                        duration: "/ transaction"
                    }
                ],
                features: ["Monthly Bookkeeping"],
            },
            {
                name: "PAYG Bookkeeping",
                description:"If you had $1 expenses for the month.",
                prices:[
                    {
                        price: "$150",
                        duration: "/ month"
                    }
                ],
                features: ["Weekly Bookkeeping","Dedicated CPA","Free State/Extension Filings"],
                sliderRequired: true
            }
        ]  
    },
    "Income Tax" : {
        categories: [
            {
                name: "State Fillings",
                plans: [
                    {
                        name: "California Form 100",
                        description:"If you have a CA entity or CA Foreign Qualification ",
                        prices:[
                            {
                                price: "$150",
                                duration: "/ yr"
                            }
                        ],
                        features: []
                    },
                    {
                        name:"Other State Income Tax Filings",
                        description:"Varies by state - only needed if you created a tax nexus in the specific state ",
                        prices:[
                            {
                                price: "$100",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    }

                ]
            },
            {
                name: "Fedaral Fillings",
                plans: [
                    {
                        name:"Form 1120(federal tax filing)",
                        description:"Primary Federal IRS tax filing (Form 1120) ",
                        prices:[
                            {
                                price: "Starts at $400",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Form 5471(if any foreign subsidiary)",
                        description:"Required if US corporation has a foreign subsidiary at any time during tax year",
                        prices:[
                            {
                                price: "$500",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Form 5472 (reportable transactions AND 25%+ foreign shareholders)",
                        description:"Required if US corporation had any reportable transactions and has any 25%+ foreign shareholder",
                        prices:[
                            {
                                price: "$100",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Form 926(foreign investment)",
                        description:"Required if US corporation is investing over $100k in a foreign entity during that tax year",
                        prices:[
                            {
                                price: "$75",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Form 8938",
                        description:"Specified US entities that have an interest in specified foreign financial assets and meet the reporting threshold",
                        prices:[
                            {
                                price: "$75",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Form 7004 (tax filing extension request)",
                        description:"We'll file it on your behalf",
                        prices:[
                            {
                                price: "Free",
                                duration: "/ transaction"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Estimated Tax Payments",
                        description:"Only applicable if you made a profit - we'll help you file it with your EFTPS pin or file it on your behalf",
                        prices:[
                            {
                                price: "Starts at $300",
                                duration: "/ yr"
                            }
                        ],
                        features:[]
                    }
                ]
            }
        ]
    },
    "Sales Tax" : {
        categories: [
            {
                name: "State Fillings",
                plans: [
                    {
                        name:"Sales Tax Nexus Study",
                        description:"All US States",
                        prices:[
                            {
                                price: "$200",
                                duration: "for  <5 states"
                            },
                            {
                                price: "$50",
                                duration: "/ state for ≥ 5 states"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Sales Tax Registrations",
                        description:"All US States",
                        prices:[
                            {
                                price: "$150",
                                duration: "for  <10 states"
                            },
                            {
                                price: "$100",
                                duration: "/ state for ≥ 10 states"
                            }
                        ],
                        features:[]
                    },
                    {
                        name:"Sales Tax Monthly and Quarterly filings",
                        description:"All US States - filed monthly/quarterly",
                        prices:[
                            {
                                price: "$50",
                                duration: "/ filing"
                            }
                        ],
                        features:[]
                    }
                ]
            },
        ]
    }
}

export default PricingOptions;