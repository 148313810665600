import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useEffect, useState } from 'react';
import '../Css/TalkToUs.css';
import closeIcon from '../Assets/multiply.png';
import redirectIcon from '../Assets/upper right arrow.png';
import useModal from "../hooks/useModal";
import { useCart } from '../context/CartProvider';
import { isValidPhoneNumber } from 'react-phone-number-input';
import formSparkApi from '../Apis/FormSparkApis';

const TalkToUsComponent = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [country, setCountry] = useState('US');
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const { isCartSubmit, setModalVisibility } = useModal();
    const [disabled, setDisabled] = useState(true);
    const {cartPlans, resetCart} = useCart();

    const handleConsultationRequest = async() => {
        setDisabled(true);
        const user = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber
        };

        await formSparkApi.submitConsultationForm(user);
        setDisabled(false);
        setModalVisibility(false);
    }

    const handleCartSubmissionRequest = async() => {
        setDisabled(true);
        const user = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber
        };
        const cart = cartPlans;
        await formSparkApi.submitCartForm(cart, user);
        setDisabled(false);
        resetCart();
        setModalVisibility(false);
    }

    const handleSetPhoneNumber = (value) => {
        if(value) {
            setPhoneNumber(value);
            setPhoneNumberError(true);
        }
    }

    const validateInput = () => {
        if(firstName && lastName && email && validatePhoneInput()) {
            return true;
        } else {
            return false;
        }
    }

    const validatePhoneInput = () => {
        return isValidPhoneNumber(phoneNumber, country);
    }

    useEffect(() => {
        if(validateInput()){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [firstName, lastName, phoneNumber, email])

    return (
        <div className="modal-form-container">
            <div className="modal-form-card">
                <div className="modal-form-header">
                    {isCartSubmit && <div className="modal-form-title"> - Fill details to save cart!</div>}
                    {!isCartSubmit && <div className="modal-form-title"> - Talk to us!</div>}
                    <div>
                        <img src={closeIcon} alt="close icon" className="modal-form-close-button" onClick={() => setModalVisibility(false)} />
                    </div>

                </div>
                <div className="modal-form-body">
                    <div className="modal-form-group">
                        <div className="modal-form-control-container">
                            <input type="text" name="first-name" placeholder="First Name*" className='modal-form-control' value = {firstName} onInput = {(event) => {
                                setFirstName(event.target.value);
                                setFirstNameError(true);
                                }}/>
                            {firstNameError && !firstName && <span className="error-message-text">Please enter first name</span>}
                        </div>
                        <div className="breakdiv">

                        </div>
                        <div className="modal-form-control-container">
                            <input type="text" name="last-name" placeholder="Last Name*" className='modal-form-control' value = {lastName} onInput = {(event) => {
                                setLastName(event.target.value);
                                setLastNameError(true);}}/>
                            {lastNameError && !lastName && <span className="error-message-text">Please enter last name</span>}
                        </div>

                    </div>
                    <div className="modal-form-group">
                        <div className="modal-form-control-container">
                            <PhoneInput
                                international
                                addInternationalOption={false}
                                value={phoneNumber}
                                onChange={handleSetPhoneNumber}
                                defaultCountry='US'
                                onCountryChange={setCountry}
                                placeholder='phonenumber'
                                style={{
                                    flex: 1,
                                    border: "1px solid var(--secondary-text-color)",
                                    borderRadius: "5px",
                                    backgroundColor: "#E1E9F5"
                                }}

                            />
                            {phoneNumberError && !validatePhoneInput() && <span className="error-message-text">Please enter a valid phone number</span>}
                        </div>
                    </div>
                    <div className="modal-form-group">
                        <div className="modal-form-control-container">
                            <input type="text" name="email" placeholder="Email*" className='modal-form-control' value = {email} onInput = {(event) => {
                                setEmail(event.target.value);
                                setEmailError(true)}}/>
                            {emailError && !email && <span className="error-message-text">Please enter a valid email</span>}
                        </div>
                    </div>
                    <div className="modal-form-group">
                        <div className={`modal-form-submit ${!disabled ? "": "disabled"}`} onClick = {() => {
                            if(disabled) {
                                console.log("Nothing happens");
                                return;
                            }
                            if(!isCartSubmit) {
                                handleConsultationRequest()
                                //Save consultation type
                            } else {
                                handleCartSubmissionRequest()
                                //Save cart type
                            }
                        }}>
                            <div className="modal-form-submit-message">LET'S CONNECT</div>
                            <div className='modal-form-redirection-icon-container'><img src={redirectIcon} alt="" className="redirection-icon" /></div>
                        </div>
                    </div>
                    <span className='footnote'>WE'LL CONTACT YOU SHORTLY!</span>

                </div>
            </div>
        </div>
    );
}

export default TalkToUsComponent;