import axios from "axios";

const PlansApi = () => {
    const getAllPlans = async () => {
        try {
            let data = JSON.stringify({
                query: `query GetAllPlans @cached {
                plans {
                  category
                  create_at
                  id
                  is_deleted
                  name
                  type
                  updated_at
                  prices
                  features
                  description
                  min_val
                  max_val
                  plan_type
                }
              }`,
                variables: {}
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: {
                    'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const getAllPlansResponse = (await axios(config)).data.data.plans;
            return getAllPlansResponse;

        } catch (error) {
            console.error(`get All Plans api failed due to ${error.message ? error.message : error}`);
        }
    }

    const getPlanById = async (planId) => {
        try {
            let data = JSON.stringify({
                query: `query GetPlanById($id: Int = 0) @cached {
                plans_by_pk(id: $id) {
                  category
                  create_at
                  id
                  is_deleted
                  name
                  type
                  updated_at
                  prices
                  features
                  description
                  min_val
                  max_val
                  plan_type
                }
              }`,
                variables: { "id": planId }
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: {
                    'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const getPlanByIdResponse = (await axios(config)).data.data.plans_by_pk;
            return getPlanByIdResponse;
        } catch (error) {
            console.error(`get Plan by id api failed due to ${error.message ? error.message : error}`);
        }
    }

    const createNewPlan = async (plan) => {
        try {
            const { name, category, type, description, features, prices, sliderRequired } = plan;
            if (!sliderRequired) sliderRequired = false;
            let data = JSON.stringify({
                query: `mutation CreateNewPlan($features: [String!] = "", $prices: jsonb = "", $description: String = "", $name: String = "", $type: String = "", $category: String = "") {
                insert_plans_one(object: {features: $features, prices: $prices, description: $description, name: $name, type: $type, category: $category}) {
                  category
                  create_at
                  description
                  features
                  id
                  is_deleted
                  name
                  type
                  updated_at
                  prices
                }
              }`,
                variables: { "features": features, "prices": prices, "description": description, "name": name, "type": type, "category": category, "sliderRequired": sliderRequired }
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_HASURA_BASE_URL}`,
                headers: {
                    'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            const newPlanResponse = (await axios(config)).data.data.insert_plans_one;
            return newPlanResponse;

        } catch (error) {
            console.error(`create new plan api failed due to ${error.message ? error.message : error}`);
        }
    }

    return {
        getPlanById,
        getAllPlans
    }
}

const plansApi = PlansApi();
export default plansApi;