import adhoc_icon1 from '../Assets/ad_hoc1.svg'
import adhoc_icon2 from '../Assets/ad_hoc2.svg'
import adhoc_icon3 from '../Assets/ad_hoc3.svg'

const otherservices ={
    option1 : {
        "title": "End-to-end service",
        "icon": adhoc_icon1,
        "description":"We handle the full R&D tax claim process for you. That includes identifying eligible R&D expenses, filing the right paperwork, and making sure your credit is accurately applied through your payroll."
    },
    option2 : {
        "title": "Meticulous documentation",
        "icon": adhoc_icon2,
        "description":"Our combination of software and experts make the R&D claim process and your paperwork meticulous. We also ensure your payroll provider, tax preparers, and accountants are all on the same page."
    },
    option3 : {
        "title": "All-in-one support",
        "icon": adhoc_icon3,
        "description":"With Sage you get bookkeeping, tax, and R&D credit support all under one roof. We already know your numbers from the ground up: That means we work fast and efficiently to identify expenses and maximize your savings."    
    }
 }
 
 export default otherservices
 
 