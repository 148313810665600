import React from 'react'

const MyDocuments = () => {
  return (
    <div>
      my documents
    </div>
  )
}

export default MyDocuments
