import React from "react";
import footer from "../../Constant/Footer";
import '../../Css/Footer.css'
import footer_logo from '../../Assets/footer-final.png'
import useModal from "../../hooks/useModal";
import { Link } from "react-router-dom";

const Footer =() => {
    const {setModalVisibility, setIsCartSubmit} = useModal();
    return(
        <div>
            <div className='footer_section1'>
                {/* <div className="footer_title">See what sage can do for you</div>
                <div className="footer_input_section">
                    <input className="footer_input"/>
                    <button className="footer_button">Talk to an expert</button>
                </div> */}
            </div>
            <div className='footer_bottom'>
                <div className='footer_bottom_section1'>
                    <img src={footer_logo} className="footer_logo"/>
                    <div className="footer_contact">
                        {Object.entries(footer.support).map(([key, value]) => (
                            <p key={key}>{key}: {value}</p>
                        ))}
                    </div>
                </div>
                <div className='footer_section2'>
                    <div className="footer_menus">
                        {Object.entries(footer.menu).map(([section, links]) => (
                            <div key={section} className='footer_menu_section'>
                                <div className="footer_menu_title">{section}</div>
                                <ul>
                                    {Object.entries(links).map(([key, value]) => (
                                        <li key={key} className="footer_menu_items">
                                            
                                            {key !== "Contact Us"? <Link to = {value}>{key}</Link>: <div style = {{
                                                cursor: "pointer"
                                            }} onClick={() => {
                                                setIsCartSubmit(false);
                                                setModalVisibility(true)
                                                    
                                            }}>{key}</div>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;