import employed_icon from "../Assets/tax_type_icons/employee_912316 1.svg";
import self_employed_icon from "../Assets/tax_type_icons/Layer_x0020_1.svg";
import small_business_icon from '../Assets/tax_type_icons/suitcase_1243560 1.svg';
import children_icon from '../Assets/tax_type_icons/baby_191720 1.svg';
import home_icon from '../Assets/tax_type_icons/house_1018573 1.svg';
import house_icon from '../Assets/tax_type_icons/sold_4350329 1.svg';
import rent_icon from '../Assets/tax_type_icons/apartment_11059074 1.svg';
import loan_icon from '../Assets/tax_type_icons/education_13331856 1.svg';
import tution_icon from '../Assets/tax_type_icons/tuition_11906130 1.svg';
import insurance_icon from '../Assets/tax_type_icons/hand_2618552 1.svg';
import stocks_icon from '../Assets/tax_type_icons/statistics_14215383 1.svg';
import interest_icon from '../Assets/tax_type_icons/give-money_3133460 1.svg';
import charity_icon from '../Assets/tax_type_icons/funds_5022772 1.svg';
import disaster_icon from '../Assets/tax_type_icons/earthquake_2875964 1.svg';
import currency_icon from '../Assets/tax_type_icons/world_10850737 1.svg'

const Applicableoptions ={
    options: [
        {
            title: "Have a W-2/ am employed",
            icon: employed_icon,
            documents: ["Wage statement - form W-2s from this year(one per employer)"]
        },
        {
            title: "Self employed",
            icon: self_employed_icon,
            documents: ["1099-NEC, business/farm income and deductions support"]
        },
        {
            title: "Own a small business",
            icon: small_business_icon,
            documents: ["K-1 business/farm income and deductions support"]
        },
        {
            title: "Have children and/ or dependents",
            icon: children_icon,
            documents: []
        },
        {
            title: "Own a home",
            icon: home_icon,
            documents: ["1098, property tax bill, or mortgage statement"]
        },
        {
            title: "Sold a house",
            icon: house_icon,
            documents: ["Supporting documentation for sale or 1099-S"]
        },
        {
            title: "Own a rental property",
            icon: rent_icon,
            documents: ["Rental income/deductions support such as 1099-MISC"]
        },
        {
            title: "Have student loans",
            icon: loan_icon,
            documents: ["1098-E"]
        },
        {
            title: "Have tuition",
            icon: tution_icon,
            documents: ["1098-T"]
        },
        {
            title: "Have a ACA Health insurance",
            icon: insurance_icon,
            documents: ["1095-A"]
        },
        {
            title: "Sold stocks or mutual funds",
            icon: stocks_icon,
            documents: ["1099-B"]
        },
        {
            title: "Had $1500 more in interest / dividends",
            icon: interest_icon,
            documents: ["1099-INT, 1099-OID, or 1099-DIV"]
        },
        {
            title: "Donate to a charity",
            icon: charity_icon,
            documents: ["Charitable donation receipts"]
        },
        {
            title: "Had a federally declared disaster",
            icon: disaster_icon,
            documents: ["Home value before and after disaster, value lost items, cost to repair/replace assets"]
        },
        {
            title: "Traded Virtual Currency",
            icon: currency_icon,
            documents: ["Virtual currency transaction report"],
        }
        
    ],
    documents: [
        "Government issued photo ID (e.g., your driver's license)",
        "Social Security card",
        "Copy of 2022 tax return",
        "Any IRS or State Notices or Letters"
    ]
 }
 
 export default Applicableoptions
 
 