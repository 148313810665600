const homepage ={
   option1 : {
    "title": "Startups",
    "icon":"FaRocket",
    "description":"We understand accounting for startups. Our bookkeeping, tax, and CFO services are a perfect fit for VC–backed companies. Get the perfect fit for VC–backed companies. Get the financial support you need for forecasting and reporting.",
    "button":"For Startups"
   },
   option2 : {
    "title": "Retail & manufacturing",
    "icon":"FaShoppingCart",
    "description":"Selling and manufacturing physical goods has a host of complexities associated with it. Our team has helped retailers, distributors and manufacturers with accounting across multiple channels.",
    "button":"For Retail and manufacturing"
   },
   option3 : {
    "title": "Professional services",
    "icon":"FaPhoneAlt",
    "description":"Whether you're a construction company or a law firm, we understand the complexities of a professional services business. We can help with AP/AR, payroll and so much more, so that you can focus on your business.",
    "button":"For Professional services"
   }
}

export default homepage

