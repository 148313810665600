import React, { useState } from "react";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import logo from '../../Assets/Spyder Logo.png'
import '../../Css/Header.css';
import { IoMenu } from "react-icons/io5";
import { LiaHomeSolid } from "react-icons/lia";
import HoverMenu from "../../Components/HoverMenu";
import Drawer from 'react-modern-drawer';
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthProvider";
import { LoginPopup } from "../LoginPopup";
import userIcon from "../../Assets/user.png";
import useModal from "../../hooks/useModal";
import { useCart } from "../../context/CartProvider";

const ServiceLink = React.forwardRef((props, ref) => (
    <li ref={ref} {...props}>Service</li>
));
const IndustriesLink = React.forwardRef((props, ref) => (
    <li ref={ref} {...props}>Industries</li>
));

const MenuItemWithLink = ({ to, children }) => (
    <Link to={to}>
        <MenuItem>{children}</MenuItem>
    </Link>
);

const Header = () => {
    const { user, logOut } = UserAuth();
    const [isOpen, setIsOpen] = React.useState(false);
    const [mobileOpenedSubmenu, setMobileOpenedSubmenu] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [showMobileAuthLinks, setShowMobileAuthLinks] = useState(false);
    const {setModalVisibility, setIsCartSubmit} = useModal();
    const mobileLinkStyles = {textDecoration: 'none', color: '#000000'};
    const ProfileLink = React.forwardRef((props, ref) => (
        <li ref={ref} {...props} className="profile-container">
            <div>{user.first_name ? user.first_name : 'Guest'}</div>
            <img className = "profile-icon" src={userIcon} alt="" />
        </li>
            
    ));
    const {setIsCartOpen} = useCart();

    const toggleMobileAuthLinks = () => {
        setShowMobileAuthLinks(!showMobileAuthLinks);
    }
    const onMobileSubmenuClick = React.useCallback((key) => {
        if (key === mobileOpenedSubmenu) {
            setMobileOpenedSubmenu('');
            return;
        }
        setMobileOpenedSubmenu(key);
    }, [mobileOpenedSubmenu]);

    const toggleDrawer = React.useCallback(() => {
        setIsOpen((prevState) => !prevState)
    }, []);

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className='header'>
                <button onClick={toggleDrawer} className="mobile_nav_menu_button"><IoMenu /></button>
                <img src={logo} alt='logo' className='logo' />
                <ul className='header_menu1' onClick = {() => {setIsCartOpen(false)}}>
                    <li><Link to="/">Home</Link></li>
                    <HoverMenu MenuButton={ServiceLink}>
                        <MenuItemWithLink to="/services/personaltax">Personal Tax</MenuItemWithLink>
                        <MenuItemWithLink to="/services/businesstax">Business Tax</MenuItemWithLink>
                        <MenuItemWithLink to="/services/adhoc-accounting">Ad- Hoc Accounting</MenuItemWithLink>
                        <MenuItemWithLink to='/services/bookkeeping'>Bookkeeping</MenuItemWithLink>
                        <MenuItemWithLink to='/services/fractional-cfo'>Fractional CFO</MenuItemWithLink>
                    </HoverMenu>
                    {/* <HoverMenu MenuButton={IndustriesLink}>
                        <MenuItem>Start Ups</MenuItem>
                        <MenuItem>Retail & Manufacturing</MenuItem>
                        <MenuItem>AProfessional Services</MenuItem>
                    </HoverMenu> */}
                    <li><Link to="/pricing">Pricing</Link></li>
                    {/* <li>Strategies for Freelancers</li> */}
                </ul>
                <ul className="header_menu2">
                    {/* {user? (
                        <div>{user.first_name ? user.first_name : 'Guest'} ,<button className="header_logout" onClick={handleSignOut}> Logout</button></div>
                    ) : (
                        <li className='header_login' onClick={() => setIsModalOpen(true)}>Login</li> 
                    )} */}
                    {/* {user && <HoverMenu MenuButton={ProfileLink}>
                        <MenuItemWithLink to="/profile">My Profile</MenuItemWithLink>
                        <MenuItem><button className="header_logout" onClick={handleSignOut}> Logout</button></MenuItem>
                    </HoverMenu>}
                    {!user && <li className='header_login' onClick={() => setIsModalOpen(true)}>Login</li> } */}

                    <button className='header_button' onClick={() => {
                        setIsCartSubmit(false);
                        setModalVisibility(true)
                        }}>Talk to an expert</button>
                </ul>
            </div>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className='mobile_nav_drawer'
            >
                <div className="mobile_nav_header">
                    <img src={logo} alt='logo' className='drawer_logo' />
                </div>
                <ul className="mobile_nav_links">
                    <li><LiaHomeSolid /><span>Home</span></li>
                    <li onClick={() => onMobileSubmenuClick('services')}><span>Services</span></li>
                    <div className={`expandable ${mobileOpenedSubmenu === 'services' ? 'expanded' : ''}`}>
                        <a href='/services/personaltax'>Personal Tax</a>
                        <a href='/'>Business Tax</a>
                        <a href='/services/adhoc-accounting'> A-hoc Accounting</a>
                        <a href='/services/bookkeeping'>Book Keeping</a>
                        <a href='/services/fractional-cfo'>Fractional CFO</a>
                    </div>
                    <li><Link to='/pricing' style = {mobileLinkStyles}>Pricing </Link></li>
                    <li>Strategies for Freelancers</li>
                    {/* <li>
                        {user &&  (
                            <div className = "mobile-nav-links-logged-in-container" onClick={() => toggleMobileAuthLinks()}>
                                {user.first_name? user.first_name: "Guest"}
                                {showMobileAuthLinks && <ul className="mobile-nav-links-logged-in-list">
                                    <li><Link to='/profile' style = {mobileLinkStyles}>My Profile </Link></li>
                                    <li className="header_logout" onClick={handleSignOut}>Logout</li>
                                </ul>}
                            </div>
                        )}
                        {!user && <span className='header_login' onClick={() => setIsModalOpen(true)}>Login</span>}
                    </li> */}
                </ul>
            </Drawer>
            <LoginPopup isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} handleSignOut={handleSignOut}/>
        </>
    )
}

export default Header;
