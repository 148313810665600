import React from 'react'

const MyPlans = () => {
  return (
    <div>
      my plans
    </div>
  )
}

export default MyPlans
