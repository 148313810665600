import React from "react";
import homepage_image1 from '../Assets/Banner.png'
import '../Css/Homepage.css'
import homepage_image2 from '../Assets/homepage_image2.png'
import Options from '../Constant/Homepage'
import Services from '../Constant/Services'
import Differences from '../Constant/Differences'
import {FaRocket, FaShoppingCart, FaPhoneAlt, FaArrowRight} from "react-icons/fa";
import useModal from "../hooks/useModal";

const HomePage = (option) =>{
    const {setModalVisibility, setIsCartSubmit} = useModal();
    const getIcon = (iconName) => {
        switch (iconName) {
          case "FaRocket":
            return <FaRocket />;
          case "FaShoppingCart":
            return <FaShoppingCart />;
          case "FaPhoneAlt":
            return <FaPhoneAlt />;
          default:
            return null;
        }
      };
    
    return (
        <div>
            <div className='homepage_section1'>
                <div className="section1_left">  
                    <h1 className='section1_text1'>Serving all your accounting needs</h1>
                    <h6 className='section1_text2'>Bookkeeping, CFO, and tax services for startups and growing business.</h6>
                    <button className='section1_button' onClick={() => {
                        setIsCartSubmit(false);
                        setModalVisibility(true);
                        }}>Talk to an expert</button>
                </div>
                <img src={homepage_image1} className='section1_img'/>
            </div>
            <div className='homepage_section2'>
                <img src={homepage_image2} className='section2_img'/>
                <div className="section2_right">
                    <div className='section2_text1'>Who we are</div>
                    <div className='section2_text2'>Dedicated financial experts, just for you.</div>
                    <div className='section2_text3'>Real humans. Perfect books. Our team of small business experts imports bank statements and prepares financial statements every month.
                         Your assigned bookkeeper works in-house, and they’re backed by our in-house research team to provide you with informed answers
                        to complex questions
                    </div>
                    <button className='section2_button'>Learn More</button>
                </div>    
            </div>
            <div className='homepage_section3'>
                <div className='section3_text1'>Who we work with </div>
                <div className="section3_text2">Unparalleled expertise with growing businesses</div>
                {Object.values(Options).map((option, index) => (
                    <div key={index} className="section3_options">
                        <div className="section3_option_title_section">{getIcon(option.icon)}<span><h2 className='section3_option_title'>{option.title}</h2></span></div>
                        <p className='section3_option_description'>{option.description}</p>
                        <a className='section3_option_button'>{option.button}<span><FaArrowRight /></span></a>
                    </div>
                ))}
            </div>
            <div className='homepage_section4'>
                <div className='section4_text1'>What we do</div>
                <div className='section4_text2'>Services tailored to your needs  </div>
                <div className='section4_options_box'>
                {Object.values(Services).map((service, index) => (
                    <div key={index} className="section4_options">
                    <div className='section4_option_content'>
                        <div className='section4_option_title'>{service.title}</div>
                        <p className='section4_option_description'>{service.description}</p>
                        <a className='section4_option_button'>{service.button}</a>
                    </div>
                </div>
                ))}
                </div>

            </div>
            <div className='homepage_section5'>
                <div className='section5_text1'>Why we’re different</div>
                <div className='section5_text2'>There’s a reason growing businesses choose us</div>
                <div className='section5_options_box'>
                {Object.values(Differences).map((difference, index) => (
                    <div key={index} className="section5_options">
                        <div className='section5_option_title'>{difference.title}</div>
                        <div className='section5_option_description'>{difference.description_firstletter}<span className='section5_option_description2'>{difference.description1}</span></div>
                        <div className='section5_option_button'>{difference.description2}</div>
                    </div>
                ))}
                </div>

            </div>
            
        </div>
    )
}

export default HomePage;