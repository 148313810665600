import BookKeeping_banner from '../Assets/bookkeeping.png';
import CFO_banner from '../Assets/CFO.png'

const BookKeeping = {
   BookKeeping:{
     "banner":BookKeeping_banner,
     "title":"The best bookkeeping for growing businesses",
     "description":"You didn't start a business to track your cash flow or reconcile accounts. But we did. Sage gives you the freedom to focus on your business, knowing you'll receive accurate, detailed books every month.",
     first_section:{
        "title":"Why companies like yours choose Sage",
        answers:[
            {
                "title":"Unmatched Accuracy",
                "description":"Get accurate books delivered monthly, on the dot. Our expert team is assisted by powerful bookkeeping software that automatically eliminates common errors."
            },
            {
                "title":"Expert support and partnership",
                "description":"Get a finance expert dedicated to your business bookkeeping services. Beyond managing your books and taxes, we help you select the right payroll provider, offer financial best practices, and more."
            },
            {
                "title":"Built for growing businesses",
                "description":"With accrual basis bookkeeping and burn rate reports, we are built for growing businesses. We integrate with hundreds of tools like Bill.com, Stripe, and Gusto."
            },
            {
                "title":"Unmatched Accuracy",
                "description":"Get accurate books delivered monthly, on the dot. Our expert team is assisted by powerful bookkeeping software that automatically eliminates common errors."
            }
        ]
     },
     second_section:{
        "title":"Getting started with Sage is easy",
        answers:[
            {
                "question":"Meet your dedicated bookkeeping expert",
                "answer":"Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows."
            },
            {
                "question":"Meet your dedicated bookkeeping expert",
                "answer":"Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows."
            },
            {
                "question":"Meet your dedicated bookkeeping expert",
                "answer":"Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows."
            },
            {
                "question":"Meet your dedicated bookkeeping expert",
                "answer":"Whether you're starting fresh or switching bookkeeping services, getting started is simple. Your dedicated finance expert is there from the beginning: they will get you onboarded, learn the ins-and-outs of your finances, and partner with you as your business grows."
            }
        ]
     }
   },
   CFO:{
    "banner":CFO_banner,
    "title":"CFO Services built for your growing business",
    "description":"Accelerate your growth with expert CFO support—from accurate budgets to high-impact financial strategies.",
    first_section:{
       "title":"Want to unlock more growth? Bring in the expert CFOs.",
       answers:[
           {
               "title":"Accelerate your growth",
               "description":"Build financial strategies and models that increase cash flow, improve profitability—from customer cohort analysis to sales efficiency, like LTV and CAC"
           },
           {
               "title":"Increase control over your finances",
               "description":"Chart your company's course with accurate forecasts and budgets. We build this block-by-block, from revenue to net income."
           },
           {
               "title":"Avoid costly pitfalls",
               "description":"Establish the financial infrastructure and hygiene that's poised for growth and impresses investors, from billing to expense reimbursement."
           }
       ]
    }

   }
}

export default BookKeeping;