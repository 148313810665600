import { Routes, Route } from "react-router-dom"
import HomePage from "./pages/Homepage";
import Layout from "./pages/Layout";
import NotFound from "./pages/404";
import PersonalTax from "./pages/Personal_tax";
import Pricing from "./pages/Pricing";
import OtherServices from "./pages/OtherServices";
import BookKeeping from "./pages/BookKeeping";
import FractionalCFOTest from './pages/CFO'
import CFOData from "./Constant/BookKeeping&CFO";
import ProfilePage from "./pages/Profile";
import BusinessTax from "./pages/BusinessTax";

function Router(){
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route index element={<HomePage />}/>
                <Route path="/services/personaltax" element={<PersonalTax />}/>
                <Route path="/services/businesstax" element={<BusinessTax />}/>
                <Route path="/services/adhoc-accounting" element={<OtherServices />}/>
                <Route path="/services/bookkeeping" element={<BookKeeping />}/>
                <Route
                    path="/services/bookkeeping-test"
                    element={<FractionalCFOTest data={CFOData.BookKeeping} />}
                />
                <Route
                    path="/services/fractional-cfo"
                    element={<FractionalCFOTest data={CFOData.CFO}/>}
                />
                <Route path="/profile" element={<ProfilePage />}/>
                <Route path="/pricing" element={<Pricing />}/>
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    ); 
}
export default Router;
