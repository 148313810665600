import React from 'react'

const Bin = () => {
  return (
    <div>
      bin
    </div>
  )
}

export default Bin
