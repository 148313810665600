import React, { useState } from 'react';
import MyPlans from './ProfilePages/MyPlans';
import MyDocuments from './ProfilePages/MyDocuments';
import Profile from './ProfilePages/Profile';
import Bin from './ProfilePages/Bin';
import Settings from './ProfilePages/Settings';
import '../Css/Profile.css'


const ProfilePage = () => {
  const [selectedOption, setSelectedOption] = useState('profile');

  const renderContent = () => {
    switch (selectedOption) {
      case 'myPlans':
        return <MyPlans />;
      case 'myDocuments':
        return <MyDocuments />;
      case 'profile':
        return <Profile />;
      case 'bin':
        return <Bin />;
      case 'settings':
            return <Settings />;  
      default:
        return null;
    }
  };

  return (
    <div className="profile-page">
      <div className="left-options">
        <div onClick={() => setSelectedOption('myPlans')}>My Plans</div>
        <div onClick={() => setSelectedOption('myDocuments')}>My Documents</div>
        <div onClick={() => setSelectedOption('profile')}>Profile</div>
        <div onClick={() => setSelectedOption('bin')}>Bin</div>
        <div onClick={() => setSelectedOption('settings')}>Settings</div>
      </div>
      <div className="right-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default ProfilePage;
